.App { /* Base font style */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16.0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.8500);
  background-color: #f6f6f6;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16.0px;
}

.App .appBg {
  background-color: #f6f6f6;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
  background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
  color: #bee9ee;
}

.App .primaryBg {
  background-color: #bee9ee;
}

.App .highlightTextColor {
  color: #0093d5;
}

.App .highlightBg {
  background-color: #0093d5;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #bee9ee;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #c3ebf0;
}
.mui-btn--accent {
    background-color: #0093d5;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #069fda;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    margin-bottom: 0;
}
.mui-textfield>input, .mui-textfield>textarea {
    font-size: inherit;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.0px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 15.0px;
}
.App .headlineFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 20.0px;
  font-weight: 600;
}
.App .headlineFont * {
  font-size: 20.0px;
}
.App .navBar {
  background-color: #bee9ee;
  color: black;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: black;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

.App {
  --tab-bar-height: 54px;
}
@media (display-mode: standalone) {
  .App {
    --tab-bar-height: 90px;
  }
}
.App .tabBar {
  height: var(--tab-bar-height);
  display: flex;
  flex-direction: row;
  pointer-events: auto;
  z-index: 9999;
}
.App .tabBar > * {
  flex: 1 0 auto;
  text-align: center;
  cursor: pointer;
}
.App .tabBar div {
  box-sizing: border-box;
  font-size: 13px; /* default size for tab bar text */
  line-height: 15px;
}
@media (max-width: 767px) {
  .App .tabBar div {
    font-size: 11px; /* small screen size for tab bar text */
    line-height: 13px;
  }
}
.App .tabBar img {
  height: 24px;
  padding-top: 6px;
}
.App .tabBarMenu {
  overflow: hidden;
}
.App .tabBarPageContent {
  overflow: scroll;
  flex: 1;
}
.App .tabBarPageContent > .AppScreen {
  position: relative;
  overflow: scroll;
}
.App .tabBarPageContent > .AppScreen > .background {
  position: fixed;
  left: auto;
  top: auto;
  height: calc(100% - var(--tab-bar-height));
}
.App .tabBarPageContent > .AppScreen > .navBarContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.App .tabBarPageContent > .AppScreen > .navBarContainer > .navBar {
  position: fixed;
  left: auto;
  top: auto;
}
@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

.App .marker {
  width: 0px;
  height: 0px;
}

.App .drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.App .drawerOpen {
  transform: translateX(0%);
  transition: 0.3s;
}

.App .drawerClosedLeft {
  transform: translateX(-100%);
  transition: 0.3s;
}

.App .drawerClosedRight {
  transform: translateX(100%);
  transition: 0.3s;
}

.App .drawerContent {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.App .drawerContent > * {
  height: 100%;
}

/* This component has a fixed-size layout */
  .UnlimitedNow {
    min-height: 765px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .UnlimitedNow > .background > .containerMinHeight {
    min-height: 765px;
  }


  .UnlimitedNow > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 35px;
    width: 253px;
    margin-left: 43px;
  }
  .UnlimitedNow > .layoutFlow > .elText > * { 
  width: 253px;
  }
  


  .UnlimitedNow > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 236px;
    width: 238px;
    margin-left: 72px;
  }
  .UnlimitedNow > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  


/* Narrowest size for this element's responsive layout */
  .StartScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .StartScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .StartScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .StartScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .StartScreen > .layoutFlow > .elImage2 {
    position: relative;
    margin-top: -160px;
    width: 76px;
    margin-left: 17px;
  }
  .StartScreen > .layoutFlow > .elImage2 > * { 
  width: 76px;
  height: 113px;
  }
  
@media (min-width: 568px) {
  .StartScreen > .layoutFlow > .elImage2 {
    position: relative;
    margin-top: -160px;
    width: 76px;
    margin-left: 17px;
  }
  .StartScreen > .layoutFlow > .elImage2 > * { 
  width: 76px;
  height: 113px;
  }
  
}
@media (min-width: 768px) {
  .StartScreen > .layoutFlow > .elImage2 {
    position: relative;
    margin-top: -159px;
    width: 76px;
    margin-left: 17px;
  }
  .StartScreen > .layoutFlow > .elImage2 > * { 
  width: 76px;
  height: 112px;
  }
  
}
@media (min-width: 1024px) {
  .StartScreen > .layoutFlow > .elImage2 {
    position: relative;
    margin-top: -159px;
    width: 76px;
    margin-left: 17px;
  }
  .StartScreen > .layoutFlow > .elImage2 > * { 
  width: 76px;
  height: 112px;
  }
  
}

.StartScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .StartScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 65px;
    width: 327px;
    margin-left: 0px;
  }
  .StartScreen > .layoutFlow > .elList > * { 
  width: 327px;
  }
  
@media (min-width: 568px) {
  .StartScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 65px;
    width: 327px;
    margin-left: 0px;
  }
  .StartScreen > .layoutFlow > .elList > * { 
  width: 327px;
  }
  
}
@media (min-width: 768px) {
  .StartScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 64px;
    width: 325px;
    margin-left: 0px;
  }
  .StartScreen > .layoutFlow > .elList > * { 
  width: 325px;
  }
  
}
@media (min-width: 1024px) {
  .StartScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 64px;
    width: 325px;
    margin-left: 0px;
  }
  .StartScreen > .layoutFlow > .elList > * { 
  width: 325px;
  }
  
}

